import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from '@services/dialog.service';
import { PartyService } from '@services/party.service';

@Component({
  selector: 'app-supplier-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class SupplierContactComponent implements OnInit {

  @Input() public partyId: string | null = null;
  @Input() public type: 'SUPPLIER_DATA' | 'SUPPLIER_ORDERS' | 'CUSTOMER' | null = null;

  public contactForm: FormGroup;
  public defaultFormValues: any = {};
  public loading: boolean = false;
  public saving: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private partyService: PartyService,
    private dialogService: DialogService,
  ) {
    this.contactForm = this.formBuilder.group({
      firstname: [{
        value: "",
        disabled: true, // disabled, because otherwise the input field appears
      }, Validators.compose([Validators.required])],
      lastname: [{
        value: "",
        disabled: true,
      }, Validators.compose([Validators.required])],
      email: [{
        value: "",
        disabled: true,
      }, Validators.compose([Validators.required])],
      phone: [{
        value: "",
        disabled: true,
      }, Validators.compose([Validators.required])],
      editing: [false],
    });
  }

  ngOnInit(): void {
    this.loading = true;

    this.partyService.getContact(this.partyId || '', this.type || '').then((contact) => {
      this.defaultFormValues = contact || {};
      this.contactForm.patchValue({ ...contact });
      this.loading = false;
    }).catch(() => {
      this.loading = false;
      this.dialogService.alert(null, "Wir konnten Ihre Kontakt-Daten nicht laden.", "danger");
    });
  }

  public saveContact(): void {
    this.saving = true;

    this.partyService.setContact(this.partyId || '', this.type || '', this.contactForm.value).then(() => {
      this.saving = false;

      this.defaultFormValues = this.contactForm.value;

      this.dialogService.alert(null, "Ihre Kontakt-Daten wurden erfolgreich gespeichert.", "success");
      this.showForm(false);
    }).catch(() => {
      this.saving = false;
      this.dialogService.alert(null, "Wir konnten Ihre Kontakt-Daten nicht speichern.", "danger");
    });
  }

  public showForm(edit: boolean) {
    if (edit) {
      this.contactForm.controls['editing'].setValue(true);

      Object.keys(this.contactForm.controls).forEach((fieldName: string) => {
        this.contactForm.controls[fieldName].enable();
      });
    } else {
      Object.keys(this.contactForm.controls).forEach((fieldName: string) => {
        this.contactForm.controls[fieldName].patchValue(this.defaultFormValues[fieldName] || '');
        this.contactForm.controls[fieldName].disable();
      });

      this.contactForm.controls['editing'].patchValue(false);
    }
  }

}
