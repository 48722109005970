import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataStream } from '@models/data-stream';
import { DataStreamService } from '@services/data-stream.service';
import { DialogService } from '@services/dialog.service';

@Component({
  selector: 'app-auto-approve',
  templateUrl: './auto-approve.component.html',
  styleUrls: ['./auto-approve.component.scss']
})
export class AutoApproveComponent implements OnInit {

  @Input() dataStream: DataStream | null = null;
  @Input() partyId: string | null = null;
  @Output() refresh: EventEmitter<void> = new EventEmitter<void>();

  public form: FormGroup;
  public saving: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private dataStreamService: DataStreamService,
    private dialogService: DialogService,
  ) {
    this.form = this.formBuilder.group({
      autoApprove: [false],
    });
  }

  ngOnInit(): void {
    this.form.patchValue({ autoApprove: !!this.dataStream?.autoApprove });
  }

  public save() {
    this.saving = true;

    this.dataStreamService.setAutoApprove(this.dataStream?.id || '', this.partyId || '', this.form.value.autoApprove).then(() => {
      this.saving = false;
      this.form.markAsPristine();
      this.refresh.emit();
      this.dialogService.alert(null, 'Die Einstellung wurde gespeichert.', 'success');
    }).catch(() => {
      this.saving = false;
      this.dialogService.alert(null, 'Beim Speichern ist ein Fehler aufgetreten.', 'danger');
    });
  }


}
