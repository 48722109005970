import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ISupplierProduct } from '@app/interfaces/isupplier-product';
import { ProductService } from '@services/product.service';

@Component({
  selector: 'app-supplier-product-detail',
  templateUrl: './supplier-product-detail.component.html',
  styleUrls: ['./supplier-product-detail.component.scss']
})
export class SupplierProductDetailComponent implements OnInit {
  public id: string | null = null;
  public supplierProduct: ISupplierProduct | null = null;
  public loading: boolean = false;
  public imageUrls: Map<string, string> = new Map();
  public documentUrls: Map<string, string> = new Map();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.reload();
  }

  public reload() {
    this.productService.fetchProduct(this.id).then((result) => {
      if (result != null) {
        this.supplierProduct = result;
      }
    })

    this.productService.fetchProductImages(this.id).then((urls: Map<string, string>) => {
      this.imageUrls = urls;
    });

    this.productService.fetchProductDocuments(this.id).then((urls: Map<string, string>) => {
      this.documentUrls = urls;
    });
  }

  public navigateToDataCatalog(dataCatalogId: string) {
    this.router.navigate(['app', 'admin', 'data-catalogs', dataCatalogId]);
  }
}
