import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { EmployerService } from '@services/employer.service';
import { PartyService } from '@services/party.service';
import { DataStreamService } from '@services/data-stream.service';

import { DataStream } from '@models/data-stream';
import { Party } from '@models/party';

@Component({
  selector: 'app-cockpit',
  templateUrl: './cockpit.component.html',
  styleUrls: ['./cockpit.component.scss']
})
export class CockpitComponent implements OnInit {

  public dataAccessesRefreshSubject: Subject<void> = new Subject<void>();
  public orderAccessesRefreshSubject: Subject<void> = new Subject<void>();

  public currentPartyId: string | null = null;

  public showDataManagement: boolean = false;
  public dataStreams: DataStream[] | null = [];

  public loading: boolean = true;
  public showDataUpload: boolean = false;
  public showDataApproval: boolean = false;
  public showOrderApproval: boolean = false;

  constructor(
    private employerService: EmployerService,
    private partyService: PartyService,
    private dataStreamService: DataStreamService,
  ) { }

  ngOnInit(): void {
    this.employerService.getCurrentEmployerParty$().subscribe((currentParty: Party | null) => {
      this.dataStreams = null;

      if (!currentParty) {
        return;
      }

      this.currentPartyId = currentParty.partyId;

      this.dataStreamService.getDataStreamsByParty(this.currentPartyId).then((dataStreams: DataStream[] | null) => {
        this.dataStreams = dataStreams;
        this.showDataApproval = dataStreams?.length! > 0;
      });

      this.loading = true;
      this.partyService.getParty(this.currentPartyId, true).then((supplier) => {
        this.showDataUpload = !!supplier?.relationships?.some((rel) => rel.type === 'SUPPLIER_DATA_UPLOAD_RELATIONSHIP');
        this.showDataApproval = !!supplier?.relationships?.some((rel) => rel.type === 'SUPPLIER_DATA_STREAM_RELATIONSHIP');
        this.showOrderApproval = !!supplier?.relationships?.some((rel) => rel.type === 'SUPPLIER_ORDER_RELATIONSHIP');
        this.loading = false;
      });
    });

  }

  public refreshDataAccesses() {
    this.dataAccessesRefreshSubject.next();
  }

}
