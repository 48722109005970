<h5>Daten-Freigabe</h5>
<h6>Automatisch oder manuell:</h6>

<form class="form-group" novalidate [formGroup]="form">
	<div class="form-check">
		<input class="form-check-input" type="radio" formControlName="autoApprove" id="isTrue" [value]="true" />
		<label class="form-check-label text-success" for="isTrue">Alle Handelspartner automatisch für den Artikeldaten-Zugriff freigeben</label>
	</div>
	<div class="form-check mt-3">
		<input class="form-check-input" type="radio" formControlName="autoApprove" id="isFalse" [value]="false" />
		<label class="form-check-label text-danger" for="isFalse">Nur ausgewählte Handelspartner auf Anfrage für Artikeldaten-Zugriff freigeben</label>
	</div>

	<button class="btn btn-success btn-sm btn-block px-3 mt-2" type="button" (click)="save()" *ngIf="form.dirty">
		<fa-icon icon="check" class="me-1"></fa-icon>
		Speichern
		<fa-icon icon="spinner" [spin]="true" *ngIf="saving" class="ms-1"></fa-icon>
	</button>
</form>
