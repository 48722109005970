<div class="pb-0 ps-1 pe-1 pt-1 border rounded datastream" [formGroup]="parentFormGroup">
	<div class="form-check" *ngIf="parentFormGroup" formArrayName="dataStreamIds">
		<input class="form-check-input" type="checkbox" [id]="'tile' + dataStream.id" [value]="dataStream.id" (change)="dataStreamIdChange($event)" #inputControl />

		<div class="tile-label" *ngIf="viewSetting == 'TILE'">
			<label class="form-check-label d-block" [for]="'tile' + dataStream.id">
				<div class="row">
					<div [ngClass]="{ 'col-7 pe-0': dataStream.logoImageUrl || supplierContact, col: !(dataStream.logoImageUrl || supplierContact) }">
						<strong class="d-block fw-semibold text-overflow">{{ dataStream.supplier?.attributes?.['NAME'] }}</strong>
						<small *ngIf="dataStream.supplier! | identification : 'GLN'" class="d-block"><strong>GLN:</strong> {{ dataStream.supplier! | identification : "GLN" }}</small>
						<small class="d-block"><strong>Produkte:</strong> {{ dataStream.productCount || 0 | number }}</small>
					</div>

					<div class="col-5 logo d-flex flex-column justify-content-between" *ngIf="dataStream.logoImageUrl || supplierContact">
						<div>
							<img class="img-fluid" *ngIf="dataStream.logoImageUrl" [src]="dataStream.logoImageUrl" />
						</div>

						<div class="text-end">
							<button (click)="showContact()" class="btn btn-link py-0 px-1" *ngIf="supplierContact" type="button">
								<fa-icon icon="comment"></fa-icon>
							</button>
						</div>
					</div>
				</div>
			</label>

			<small class="d-block fw-bold text-danger text-uppercase" *ngIf="dataStream.firstImportDate! < newMinDate">Neu</small>
		</div>
		<div class="list-label row" *ngIf="viewSetting == 'LIST'">
			<label class="col-4" [for]="'tile' + dataStream.id">
				<strong class="d-block">{{ dataStream.supplier?.attributes?.['NAME'] }}</strong>
				<small *ngIf="dataStream.supplier! | identification : 'GLN'" class="d-block"><strong>GLN:</strong> {{ dataStream.supplier! | identification : "GLN" }}</small>
			</label>
			<label class="col mb-0" [for]="'tile' + dataStream.id">
				<small class="d-block"><strong>Produkte:</strong> {{ dataStream.productCount || 0 | number }}</small>
			</label>
			<div class="col-1 text-end" *ngIf="dataStream.firstImportDate! < newMinDate">
				<span class="d-block fw-bold text-danger text-uppercase">Neu</span>
			</div>
			<div class="col-1 text-end" *ngIf="supplierContact">
				<button (click)="showContact()" class="btn btn-link btn-sm" type="button">
					<fa-icon icon="comment"></fa-icon>
				</button>
			</div>
		</div>
	</div>
</div>

<ng-template #contactModal let-modal>
	<div class="modal-header">
		<h5 class="modal-title">Ansprechpartner für Daten</h5>
		<button type="button" class="btn-close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<p>Bei etwaigen Rückfragen können Sie sich jederzeit an den Lieferanten wenden:</p>
		<div *ngIf="supplierContact">
			<strong class="d-block">Ansprechpartner:</strong>
			<div>
				{{ supplierContact.firstname }}
				{{ supplierContact.lastname }}
			</div>

			<div>
				E-Mail: <a href="mailto:{{ supplierContact.email }}">{{ supplierContact.email }}</a>
			</div>

			<div>Telefon: {{ supplierContact.phone }}</div>
		</div>
	</div>
</ng-template>
