import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { DialogService } from '@services/dialog.service';
import { SupplierService } from '@services/supplier.service';

import { IOrderAccess } from '@app/interfaces/iorder-access';

@Component({
  selector: 'app-order-accesses',
  templateUrl: './order-accesses.component.html',
  styleUrls: ['./order-accesses.component.scss']
})
export class OrderAccessesComponent implements OnInit, OnDestroy {

  @Input() partyId: string | null = null;

  @Input() refresh: Observable<void> = new Observable<void>();
  private refreshSubscription!: Subscription;

  public accesses: IOrderAccess[] | null = [];
  public loading: boolean = false;

  constructor(
    private supplierService: SupplierService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    if (!this.partyId) {
      return;
    }

    this.refreshAccesses();

    this.refreshSubscription = this.refresh.subscribe(() => {
      this.refreshAccesses();
    });
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }

  private refreshAccesses() {
    this.loading = true;
    this.supplierService.getOrderAccesses(this.partyId!).then((accesses: IOrderAccess[] | null) => {
      this.accesses = accesses?.sort((a, b) => a.requestDate < b.requestDate ? 1 : a.requestDate > b.requestDate ? -1 : 0) || [];
      this.loading = false;
    });
  }

  public setAccess(access: IOrderAccess, status: string) {
    access.loading = true;

    this.supplierService.setOrderAccess(this.partyId!, access.customerPartyId, status).then(() => {
      this.accesses = this.accesses?.map((a) => {
        if (a.customerPartyId === access.customerPartyId) {
          a.statusId = status;
          a.responseDate = new Date();
        }
        return a;
      }) || [];
      access.loading = false;
    }).catch(() => {
      access.loading = false;
      this.dialogService.alert(null, 'Leider ist etwas schief gelaufen.', 'danger');
    });
  }

}
