<div class="p-2 border rounded datastream">
	<div class="row">
		<div [ngClass]="{ 'col-7 pe-0': dataStream.logoImageUrl, col: !dataStream.logoImageUrl }">
			<strong class="d-block fw-semibold">{{ dataStream.supplier?.attributes?.['NAME'] }}</strong>
			<small *ngIf="dataStream.supplier! | identification : 'GLN'" class="d-block"><strong>GLN:</strong> {{ dataStream.supplier! | identification : "GLN" }}</small>
			<small class="d-block"><strong>Produkte:</strong> {{ dataStream.productCount || 0 }}</small>
		</div>

		<div class="col-5 logo" *ngIf="dataStream.logoImageUrl">
			<img class="img-fluid" [src]="dataStream.logoImageUrl" />
		</div>

		<button (click)="showContact()" class="btn-link float-right" *ngIf="dataStream.contactData">
			<fa-icon icon="comment"></fa-icon>
		</button>
	</div>

	<small class="d-block fw-bold text-danger text-uppercase" *ngIf="dataStream.firstImportDate! < newMinDate">Neu</small>

	<button (click)="sendRequest()" class="btn btn-secondary btn-sm d-block w-100 mt-2" [disabled]="sendingRequest || dataStream.statusId">
		<fa-icon icon="key" class="me-1"></fa-icon>
		<span *ngIf="!dataStream.statusId">Zugriff anfordern</span>
		<span *ngIf="dataStream.statusId! == 'REQUESTED'">Zugriff angefordert</span>
		<span *ngIf="dataStream.statusId! == 'DENIED'">Zugriff abgelehnt</span>
		<fa-icon icon="spinner" class="ms-1" [spin]="true" *ngIf="sendingRequest"></fa-icon>
	</button>
</div>

<ng-template #contactModal let-modal>
	<div class="modal-header">
		<h5 class="modal-title">Ansprechpartner für Daten</h5>
		<button type="button" class="btn-close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body pb-0">
		<p>Bei etwaigen Rückfragen können Sie sich jederzeit an den Lieferanten wenden:</p>
		<div *ngIf="dataStream.contactData">
			<strong class="d-block">Ansprechpartner::</strong>
			<div>
				{{ dataStream.contactData.firstName }}
				{{ dataStream.contactData.lastName }}
			</div>

			<div>
				E-Mail: <a href="mailto:{{ dataStream.contactData.email }}">{{ dataStream.contactData.email }}</a>
			</div>

			<div>Telefon: {{ dataStream.contactData.phone }}</div>
		</div>
	</div>
</ng-template>
