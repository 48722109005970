<button class="btn btn-danger d-block w-100 mb-2" (click)="resetProductData()" *ngIf="isAdmin" [disabled]="dataCatalogStatus?.status != 'READY'">{{ "Template.MainComponent.label_deleteProducts" | translate }}</button>

<a class="btn btn-secondary d-block w-100" [href]="'/assets/data/supplier-data-template-' + currentLanguage?.toUpperCase() + '.xlsx'" target="_blank">{{ "Template.MainComponent.label_downloadTemplate" | translate }} </a>
<a class="btn btn-link btn-sm d-block w-100 mb-3 text-center" [href]="'/assets/data/supplier-data-template-description-' + currentLanguage?.toUpperCase() + '.pdf'" target="_blank">
	<fa-icon [icon]="'info-circle'" class="mr-2"></fa-icon>
	{{ "Template.MainComponent.label_downloadTemplateDescription" | translate }}
</a>

<button class="btn btn-secondary d-block w-100 mb-2" (click)="getUploadedFile()" *ngIf="dataCatalogStatus?.status != 'PRISTINE'">{{ "Template.MainComponent.label_downloadUploadedFile" | translate }}</button>

<button class="btn btn-secondary d-block w-100 mb-2" (click)="requestDownload('PIM')" *ngIf="isAdmin && dataCatalogStatus?.status == 'READY'" [disabled]="requestingDownload">
	{{ "Template.MainComponent.label_downloadGeneratedFile" | translate }} (Format PIM)
	<fa-icon [icon]="'spinner'" [spin]="true" *ngIf="requestingDownload"></fa-icon>
</button>

<button class="btn btn-secondary d-block w-100 mb-2" (click)="requestDownload('SUPPLIER_PORTAL')" *ngIf="dataCatalogStatus?.status == 'READY'" [disabled]="requestingDownload">
	{{ "Template.MainComponent.label_downloadGeneratedFile" | translate }}
	<span *ngIf="isAdmin">({{ "Template.MainComponent.label_format" | translate }} Supplier Portal)</span>
	<fa-icon [icon]="'spinner'" [spin]="true" *ngIf="requestingDownload"></fa-icon>
</button>
