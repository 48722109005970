import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Customer } from '@models/customer';
import { Party } from '@models/party';
import { Supplier } from '@models/supplier';
import { User } from '@models/user';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@services/dialog.service';
import { ErrorService } from '@services/error.service';
import { PartyService } from '@services/party.service';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {
  @Input()
  party!: Customer | Supplier | null;
  @Input()
  username!: string;
  @Input() isCustomer: boolean = false; // true -> Customer, false -> Supplier

  @Output() loadingEvent = new EventEmitter<boolean>();
  @Output() reloadEvent = new EventEmitter<void>();

  public user: User | null = null;
  public userParty: Party | null = null;
  public employeeForm: FormGroup;
  public defaultFormValues: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private viewContainerRef: ViewContainerRef,
    private userService: UserService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private translateService: TranslateService,
    private partyService: PartyService
  ) {
    this.employeeForm = this.formBuilder.group({
      firstname: [{
        value: "",
        disabled: true, // disabled, because otherwise the input field appears
      },
      Validators.compose([Validators.required])
      ],
      lastname: [{
        value: "",
        disabled: true,
      },
      Validators.compose([Validators.required])
      ],
      editing: [false],
    });
  }

  ngOnInit(): void {
    this.loadEmployee(this.username);
  }

  private initForm() {
    this.employeeForm.controls['editing'].patchValue(false);
    this.employeeForm.controls['firstname'].disable();
    this.employeeForm.controls['lastname'].disable();
  }

  private loadEmployee(username: string | null): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!username) {
        return reject();

      } else {
        // Load employee from API
        return this.userService.getUser(username).then((user: User) => {
          this.user = user;
          this.employeeForm.controls['firstname'].setValue(this.user?.firstname);
          this.employeeForm.controls['lastname'].setValue(this.user?.lastname);

          return this.partyService.getParty(user.partyId!, true);
        }).then((party: Party | null) => {
          this.userParty = party;

          return resolve();
        }).catch(() => {
          return reject();
        });
      }
    });
  }

  public async toggleUser(user: User, enable: boolean) {
    if (! await this.dialogService.confirm(
      `${enable ? this.translateService.instant('Dialog.EmployeesComponent.text_enableUser') : this.translateService.instant('Dialog.EmployeesComponent.text_disableUser')}`,
      `${enable ? this.translateService.instant('Dialog.EmployeesComponent.text_confirmEnableUser') : this.translateService.instant('Dialog.EmployeesComponent.text_confirmDisableUser')}`,
      this.viewContainerRef
    )) {
      return;
    }

    this.loadingEvent.emit(true);

    this.userService.updateEnabled(enable, user?.username).then(() => {
      this.dialogService.alert(null, `${enable ? this.translateService.instant('Dialog.EmployeesComponent.text_UserEnabled') : this.translateService.instant('Dialog.EmployeesComponent.text_UserDisabled')}`, 'success');
      this.reload();
      this.loadingEvent.emit(false);
    }).catch((error: string) => {
      this.dialogService.alert(null, error || `${enable ? this.translateService.instant('Dialog.EmployeesComponent.text_errEnablingUser') : this.translateService.instant('Dialog.EmployeesComponent.text_errDisablingUser')}`, 'danger');
      this.loadingEvent.emit(false);
    });
  }

  public async unlinkUser(user: User) {
    if (! await this.dialogService.confirm(
      this.translateService.instant('Dialog.EmployeesComponent.text_UnlinkUser'),
      this.translateService.instant('Dialog.EmployeesComponent.text_conformUnlinkUser'),
      this.viewContainerRef
    )) {
      return;
    }

    if (!this.party || !this.party.partyId || !user || !user.partyId) {
      this.errorService.printError("Either party or user are empty.");
      return;
    }

    this.loadingEvent.emit(true);
    
    this.userService.unlinkUser(this.party.partyId, user.partyId).then(() => {
      this.dialogService.alert(null, this.translateService.instant('Dialog.EmployeesComponent.text_userUnlinked'), 'success');
      this.reload();
      this.loadingEvent.emit(false);
      this.reloadEvent.emit();
    }).catch((error: string) => {
      this.dialogService.alert(null, error || this.translateService.instant('Dialog.EmployeesComponent.text_errUserNotUnlinked'), 'danger');
      this.loadingEvent.emit(false);
    })
  }

  public async deleteUser(user: User) {
    if (! await this.dialogService.confirm(
      this.translateService.instant('Dialog.EmployeesComponent.text_removeUser'),
      this.translateService.instant('Dialog.EmployeesComponent.text_confirmRemoveUser'),
      this.viewContainerRef
    )) {
      return;
    }

    this.loadingEvent.emit(true);

    this.userService.deleteUser(user!.username).then(() => {
      this.dialogService.alert(null, this.translateService.instant('Dialog.EmployeesComponent.text_userRemoved'), 'success');
      this.reload();
      this.loadingEvent.emit(false);
      this.reloadEvent.emit();
    }).catch((error: string) => {
      this.dialogService.alert(null, error || this.translateService.instant('Dialog.EmployeesComponent.text_userNotRemoved'), 'danger');
      this.loadingEvent.emit(false);
    })
  }

  public async resetPassword(user: User) {
    if (! await this.dialogService.confirm(
      this.translateService.instant('Dialog.EmployeesComponent.text_resetPassword'),
      this.translateService.instant('Dialog.EmployeesComponent.text_confirmResetPassword'),
      this.viewContainerRef
    )) {
      return;
    }

    this.loadingEvent.emit(true);

    this.userService.resetPassword(user.email).then(() => {
      this.dialogService.alert(null, this.translateService.instant('Dialog.EmployeesComponent.text_passwordReset'), 'success');
      this.reload();
      this.loadingEvent.emit(false);
    }).catch((error: string) => {
      this.dialogService.alert(null, error || this.translateService.instant('Dialog.EmployeesComponent.text_errPasswordNotReset'), 'danger');
      this.loadingEvent.emit(false);
    });
  }

  public setUserAttribute(user: User, attributeTypeId: string, attributeValue: string) {
    if (!user || !user.partyId) {
      return;
    }
    this.loadingEvent.emit(true);

    this.partyService.setAttribute(user.partyId, attributeTypeId, attributeValue).then(() => {
      this.dialogService.alert(null, this.translateService.instant('Dialog.EmployeesComponent.text_attributeUpdated'), 'success');

      return this.reload();
    }).then(() => {
      this.loadingEvent.emit(false);
    }).catch((error: string) => {
      this.dialogService.alert(null, error || this.translateService.instant('Dialog.EmployeesComponent.text_errAttributeNotUpdated'), 'danger');
      this.loadingEvent.emit(false);
    });
  }

  public updateUserName(user: User, firstname: string, lastname: string, formGroup: FormGroup<any>) {
    user.loading = true;

    this.userService.updateName(firstname, lastname, user.username).then(() => {
      user.loading = false;
      this.reload();
    }).catch((error: string) => {
      user.loading = false;
      this.dialogService.alert(null, error || this.translateService.instant('Dialog.EmployeesComponent.text_errNameNotUpdated'), 'danger');
    })
  }

  public reload(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.party != null) {
        this.defaultFormValues = this.party;
      }
      return this.loadEmployee(this.username).then(() => {
        this.initForm();

        return resolve();
      }).catch(() => {
        return reject();
      });
    });
  }

  public showForm(form: any, defaultValues: any, editingControl: any) {
    if (editingControl) {
      if (editingControl.value) {
        editingControl.patchValue(false);

        Object.keys(form.controls).forEach((fieldName: string) => {
          form.controls[fieldName].patchValue(this.defaultFormValues[fieldName] || defaultValues[fieldName] || '');
          form.controls[fieldName].disable();
        });
      } else {
        editingControl.patchValue(true);

        Object.keys(form.controls).forEach((fieldName: string) => {
          form.controls[fieldName].enable();
        });
      }
    }
  }

}
