import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ControlContainer, FormArray, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { DataStream } from '@models/data-stream';
import { PartyContact } from '@models/party-contact';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-data-stream',
  templateUrl: './data-stream.component.html',
  styleUrls: ['./data-stream.component.scss'],
})
export class DataStreamComponent implements OnInit {

  @Input() dataStream!: DataStream;
  @Input() supplierContact!: PartyContact;
  @Input() viewSetting: string = 'TILE';
  @Input() parentFormGroup!: FormGroup;

  @Input() select: Observable<boolean> = new Observable<boolean>();
  private selectSubscription!: Subscription;

  @ViewChild('contactModal') private modalContent!: TemplateRef<any>;
  private modalRef!: NgbModalRef;

  @ViewChild('inputControl') private inputControl!: ElementRef<any>;

  public newMinDate: Date = new Date();

  constructor(
    private modalService: NgbModal,
  ) {
    this.newMinDate.setDate(this.newMinDate.getDate() - 14);
  }

  ngOnInit(): void {
    this.selectSubscription = this.select.subscribe((select: boolean) => {
      this.selectOrUnselectDataStream(select);
    });
  }

  ngOnDestroy() {
    this.selectSubscription.unsubscribe();
  }

  public showContact(): void {
    this.modalRef = this.modalService.open(this.modalContent)
  }

  public closeContact() {
    this.modalRef.close();
  }

  public dataStreamIdChange(event: any): void {
    this.selectOrUnselectDataStream(event.target?.checked!);
  }

  public selectOrUnselectDataStream(selected: boolean): void {
    const dataStreamIds = <FormArray>this.parentFormGroup.controls['dataStreamIds'];
    if (selected) {
      dataStreamIds.push(new FormControl(this.dataStream.id));
    } else {
      let index = dataStreamIds.controls.findIndex(x => x.value == this.dataStream.id);
      dataStreamIds.removeAt(index);
    }

    this.inputControl.nativeElement.checked = selected;
  }


}
