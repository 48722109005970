import { Pipe, PipeTransform } from '@angular/core';
import { DataStream } from '@models/data-stream';

@Pipe({
  name: 'datastreamFilter'
})
export class DatastreamFilterPipe implements PipeTransform {

  transform(dataStreams: DataStream[], ...args: string[]): DataStream[] {
    const searchText = args[0].toLowerCase();

    if (searchText) {
      dataStreams = dataStreams.filter((dataStream: DataStream) => {
        const identifications = dataStream?.supplier?.identifications || [] as any[];

        const gln = identifications.find((i) => i.type == 'GLN')?.value;
        const name = dataStream.supplier?.attributes?.['NAME'];

        return name?.toLowerCase().includes(searchText) || gln?.toLowerCase().includes(searchText);
      });
    }

    return dataStreams;
  }

}
