<h5>Lieferkonditionen</h5>

<h6>für ZR-Bestellungen:</h6>

<p>
	<small>Bitte geben Sie hier Ihre Lieferkonditionen für ZR-Bestellungen an. </small>
</p>

<div class="text-center" *ngIf="loading">
	<fa-icon icon="spinner" [spin]="loading" size="2x"></fa-icon>
</div>

<form [formGroup]="deliveryConditionsForm" novalidate *ngIf="!loading">
	<div class="form-group mb-2">
		<label for="deliveryMinimumLimit" class="mb-0">Mindestbestellwert</label>
		<div class="input-group input-group-sm">
			<span class="input-group-text">€</span>
			<input type="number" [ngClass]="deliveryConditionsForm.controls['deliveryMinimumLimit'].disabled ? 'form-control-plaintext' : 'form-control'" formControlName="deliveryMinimumLimit" placeholder="0,00" step="0.01" />
		</div>
	</div>
	<div class="form-group mb-2">
		<label for="deliveryNormalLimit" class="mb-0">Freihaus-Grenze</label>
		<div class="input-group input-group-sm">
			<span class="input-group-text">€</span>
			<input type="number" [ngClass]="deliveryConditionsForm.controls['deliveryNormalLimit'].disabled ? 'form-control-plaintext' : 'form-control'" formControlName="deliveryNormalLimit" placeholder="0,00" step="0.01" />
		</div>
	</div>
	<div class="form-group mb-2">
		<label for="minimumQuantitySurcharge" class="mb-0">Mindermengen-Zuschlag</label>
		<div class="input-group input-group-sm">
			<span class="input-group-text">€</span>
			<input type="number" [ngClass]="deliveryConditionsForm.controls['minimumQuantitySurcharge'].disabled ? 'form-control-plaintext' : 'form-control'" formControlName="minimumQuantitySurcharge" placeholder="0,00" step="0.01" />
		</div>
	</div>
	<div class="form-group mb-2">
		<label for="deliveryNotes" class="mb-0">Weitere Hinweise</label>
		<textarea [ngClass]="deliveryConditionsForm.controls['deliveryNotes'].disabled ? 'form-control form-control-plaintext' : 'form-control'" formControlName="deliveryNotes" placeholder="Weitere Hinweise"></textarea>
	</div>

	<button class="btn btn-sm btn-block w-100" (click)="showForm(!deliveryConditionsForm.controls['editing'].value)" type="button" [ngClass]="deliveryConditionsForm.controls['editing'].value ? 'btn-outline-danger' : 'btn-secondary'">
		<span *ngIf="!deliveryConditionsForm.controls['editing'].value">Bearbeiten</span>
		<span *ngIf="deliveryConditionsForm.controls['editing'].value">Abbrechen</span>
		<fa-icon icon="pencil-alt" *ngIf="!deliveryConditionsForm.controls['editing'].value" class="ms-1"></fa-icon>
		<fa-icon icon="circle-xmark" *ngIf="deliveryConditionsForm.controls['editing'].value" class="ms-1"></fa-icon>
	</button>
	<button type="button" [attr.disabled]="saving ? '' : null" class="btn btn-secondary btn-sm mt-2 btn-block w-100" *ngIf="deliveryConditionsForm.controls['editing'].value" (click)="saveDeliveryConditions()">
		Speichern
		<fa-icon icon="check" *ngIf="!saving" class="ms-1"></fa-icon>
		<fa-icon icon="spinner" [spin]="true" *ngIf="saving" class="ms-1"></fa-icon>
	</button>
</form>
