import { Pipe, PipeTransform } from '@angular/core';
import { EnrichedPartyRelationship } from '@models/enriched-party-relationship';

@Pipe({
  name: 'otherPartyRels'
})
export class OtherPartyRelsPipe implements PipeTransform {

  transform(partyRels: EnrichedPartyRelationship[], ...args: string[]): EnrichedPartyRelationship[] {
    const currentEmployerPartyId = args[0];
    if (!partyRels || partyRels.length === 0) {
      return [];
    }
    if (!currentEmployerPartyId) {
      return partyRels;
    }

    return partyRels.filter((partyRel: EnrichedPartyRelationship) => partyRel.partyId != currentEmployerPartyId);
  }

}
