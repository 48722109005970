import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { DialogService } from '@services/dialog.service';
import { SupplierService } from '@services/supplier.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-supplier-header-image-upload',
  templateUrl: './supplier-header-image-upload.component.html',
  styleUrls: ['./supplier-header-image-upload.component.scss']
})
export class SupplierHeaderImageUploadComponent implements OnInit, OnDestroy {

  @Input() partyId: string | null = null;

  @Input() refresh: Observable<void> = new Observable<void>();
  private refreshSubscription!: Subscription;

  public loading: boolean = false;
  public saving: boolean = false;

  @ViewChild("filePicker") inputVar: ElementRef | undefined;

  public imageForm = new FormGroup({
    imageBlob: new FormControl(''),
    imageData: new FormControl(''),
    imageUrl: new FormControl(null),
  });

  constructor(
    private supplierService: SupplierService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    if (!this.partyId) {
      return;
    }

    this.refreshHeaderImage();

    this.refreshSubscription = this.refresh.subscribe(() => {
      this.refreshHeaderImage();
    });
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }

  public refreshHeaderImage() {
    this.loading = true;

    this.supplierService.getHeaderImage(this.partyId!).then((imageUrl: any) => {
      this.loading = false;

      this.imageForm.patchValue({
        imageUrl: imageUrl,
      });
    }).catch((error: any) => {
      this.loading = false;
    });

  }

  public onImagePicked(event: Event) {
    if (!(event?.target as HTMLInputElement)?.files?.length) {
      return;
    }

    const target = event.target as HTMLInputElement;
    if (!target.files) {
      return;
    }

    const file = target.files[0]; // Here we use only the first file (single file)
    this.imageForm.patchValue({ imageBlob: file } as any);

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      this.imageForm.get('imageData')?.patchValue(reader.result as any);
      this.imageForm.markAsDirty();
    }
  }

  public removeImage() {
    this.imageForm.get('imageUrl')?.patchValue(null);
    this.imageForm.get('imageData')?.patchValue(null);
    this.imageForm.markAsDirty();
  }

  public saveImage() {
    if (!this.partyId) {
      return;
    }

    if (this.imageForm.invalid) {
      return;
    }

    this.saving = true;

    this.supplierService.setHeaderImage(this.partyId, this.imageForm.value.imageBlob as any).then(() => {
      this.saving = false;

      this.dialogService.alert(null, 'Ihr Header-Bild wurde gespeichert.');
    }).catch((error: any) => {
      this.saving = false;

      this.dialogService.alert(null, 'Das Bild konnte leider nicht gespeichert werden.', 'danger');
    });
  }


}
