<fa-icon *ngIf="!party || !user" icon="spinner" [spin]="true"></fa-icon>

<form *ngIf="party && user" [formGroup]="employeeForm" novalidate class="rounded-bottom">
	<div class="container">
		<div class="form-group mb-0">
			<div class="row pt-1 pb-1 rounded border">
				<div class="col-xs-6 col-md-3">
					<input type="text" [ngClass]="employeeForm.controls['firstname'].disabled ? 'form-control-plaintext' : 'form-control'" formControlName="firstname" placeholder="{{ 'Template.EmployeeComponent.placeholder_firstName' | translate }}" />
				</div>
				<div class="col-xs-6 col-md-3">
					<input type="text" [ngClass]="employeeForm.controls['lastname'].disabled ? 'form-control-plaintext' : 'form-control'" formControlName="lastname" placeholder="{{ 'Template.EmployeeComponent.placeholder_lastName' | translate }}" />
				</div>
				<div class="col-xs-6 col-md-3">
					<button class="btn" (click)="showForm(employeeForm, user, employeeForm.controls['editing'])" type="button" placement="top" ngbTooltip="{{ 'Template.EmployeeComponent.tooltip_rename' | translate }}" [ngClass]="employeeForm.controls['editing'].value ? 'btn-outline-danger' : 'btn-secondary'">
						<fa-icon icon="pencil-alt" *ngIf="!employeeForm.controls['editing'].value"> </fa-icon>
						<fa-icon icon="circle-xmark" *ngIf="employeeForm.controls['editing'].value"> </fa-icon>
					</button>
				</div>
				<div class="col-xs-6 col-md-3 text-end">
					<button type="button" *ngIf="userParty?.relationships?.length! > 1" class="btn btn-link btn-sm" triggers="hover" popoverTitle="{{ 'Template.EmployeeComponent.text_moreUserAccesses' | translate }}" [ngbPopover]="popContent">
						{{ "Template.EmployeeComponent.btn_moreUserAccesses" | translate }}
						<fa-icon icon="user-friends"></fa-icon>
					</button>
					<button class="btn btn-danger" (click)="unlinkUser(user)" placement="top" ngbTooltip="{{ 'Template.EmployeeComponent.tooltip_unlinkUser' | translate }}" *ngIf="userParty?.relationships?.length! > 1">
						<fa-icon icon="unlink"></fa-icon>
					</button>
					<button class="btn btn-danger" (click)="deleteUser(user)" placement="top" ngbTooltip="{{ 'Template.EmployeeComponent.tooltip_removeUser' | translate }}" *ngIf="!(userParty?.relationships?.length! > 1)" [disabled]="!userParty">
						<fa-icon icon="user-xmark"></fa-icon>
					</button>
					<ng-template #popContent>
						{{ "Template.EmployeeComponent.text_userHasOtherLinks" | translate }}:<br />
						<span *ngFor="let partyRel of userParty?.enrichedRelationships! | otherPartyRels : party?.partyId!" class="d-block">
							{{ (partyRel.toParty?.attributes)!["NAME"] }}
						</span>
					</ng-template>
				</div>
				<div class="col-12">
					<button type="button" [attr.disabled]="user.loading ? '' : null" class="btn btn-primary mt-2" *ngIf="employeeForm.controls['editing'].value" (click)="updateUserName(user, employeeForm.controls['firstname'].value, employeeForm.controls['lastname'].value, employeeForm)">
						{{ "Template.EmployeeComponent.btn_save" | translate }}
						<fa-icon icon="check"></fa-icon>
						<fa-icon icon="spinner" [spin]="true" *ngIf="user.loading"></fa-icon>
					</button>
				</div>
			</div>
		</div>

		<div class="row mt-2">
			<div class="col-xs-6 col-md-3">
				<div class="ps-4">
					<strong>{{ user.email }}</strong>
				</div>
			</div>
			<div class="col-xs-6 col-md-3">
				<strong>{{ "Template.EmployeeComponent.text_status" | translate }}:</strong>
				<span *ngIf="!party.enabled || !user.enabled">
					{{ "Template.EmployeeComponent.text_disabled" | translate }}
					<fa-icon icon="info-circle" placement="bottom" ngbTooltip="{{ 'Template.EmployeeComponent.tooltip_userCustomerDisabled' | translate }}"> </fa-icon>
				</span>
				<span *ngIf="party.enabled && user.enabled && !user.lastLoginDate && user.requirePasswordChange">
					{{ "Template.EmployeeComponent.text_invited" | translate }}
					<br />
					{{ "Template.EmployeeComponent.text_dateTime" | translate : { dateTime: user.createdDate | date : "dd.MM.yyyy, HH:mm" } }}
				</span>
				<span *ngIf="party.enabled && user.enabled && user.lastLoginDate && user.requirePasswordChange">
					{{ "Template.EmployeeComponent.text_passwordReset" | translate }}
					<br />
					{{ "Template.EmployeeComponent.text_dateTime" | translate : { dateTime: user.passwordResetDate | date : "dd.MM.yyyy, HH:mm" } }}
				</span>
				<span *ngIf="party.enabled && user.enabled && user.lastLoginDate && !user.requirePasswordChange">
					{{ "Template.EmployeeComponent.text_enabled" | translate }}
				</span>
				<span *ngIf="party.enabled && user.enabled && !user.lastLoginDate && !user.requirePasswordChange"> Migriert </span>
			</div>
			<div class="col-xs-6 col-md-3">
				<strong>{{ "Template.EmployeesComponent.text_lastLogin" | translate }}: </strong>
				<span *ngIf="user.lastLoginDate">
					{{ "Template.EmployeesComponent.text_dateTime2" | translate : { dateTime: user.lastLoginDate | date : "dd.MM.yyyy, HH:mm" } }}
				</span>
				<span *ngIf="!user.lastLoginDate">---</span>
				<br />
				<strong>{{ "Template.EmployeesComponent.text_lastFtpLogin" | translate }}: </strong>
				<span *ngIf="user.lastFtpLoginDate">
					{{ "Template.EmployeesComponent.text_dateTime2" | translate : { dateTime: user.lastFtpLoginDate | date : "dd.MM.yyyy, HH:mm" } }}
				</span>
				<span *ngIf="!user.lastFtpLoginDate">---</span>
			</div>
			<div class="col-xs-6 col-md-3">
				<div class="text-end">
					<button class="btn btn-secondary mx-1" (click)="resetPassword(user)" placement="top" ngbTooltip="{{ 'Template.EmployeesComponent.tooltip_resetPassword' | translate }}" [disabled]="!party.enabled || !user.enabled">
						<fa-icon icon="undo"></fa-icon>
					</button>

					<button class="btn btn-success mx-1" (click)="toggleUser(user, true)" placement="top" ngbTooltip="{{ 'Template.EmployeesComponent.tooltip_enableUserAccess' | translate }}" *ngIf="!user.enabled" [disabled]="!party.enabled">
						<fa-icon icon="unlock"></fa-icon>
					</button>

					<button class="btn btn-warning mx-1" (click)="toggleUser(user, false)" placement="top" ngbTooltip="{{ 'Template.EmployeesComponent.tooltip_disableUserAccess' | translate }}" *ngIf="user.enabled" [disabled]="!party.enabled">
						<fa-icon icon="lock"></fa-icon>
					</button>

					<button class="btn btn-success mx-1" (click)="setUserAttribute(user, 'DISABLE_EMAILS', 'Y')" *ngIf="!userParty?.attributes?.['DISABLE_EMAILS'] || userParty?.attributes?.['DISABLE_EMAILS'] == 'N'" placement="top" ngbTooltip="{{ 'Template.EmployeesComponent.tooltip_excludeFromEmails' | translate }}" [disabled]="!party.enabled">
						<fa-icon icon="envelope"></fa-icon>
					</button>

					<button class="btn btn-danger mx-1" (click)="setUserAttribute(user, 'DISABLE_EMAILS', 'N')" *ngIf="userParty?.attributes?.['DISABLE_EMAILS']! == 'Y'" placement="top" ngbTooltip="{{ 'Template.EmployeesComponent.tooltip_enableEmails' | translate }}" [disabled]="!party.enabled">
						<fa-icon icon="envelope"></fa-icon>
					</button>
				</div>
			</div>
		</div>
	</div>
</form>
