<div *ngIf="dataStreams && dataStreams.length">
	<table class="table table-striped">
		<thead>
			<th>Datenstream</th>
			<th class="text-center">{{ "Template.DatastreamOverviewComponent.text_status" | translate }}</th>
			<th>Angefragt</th>
			<th>Beantwortet</th>
		</thead>

		<tbody>
			<tr *ngFor="let dataStream of dataStreams">
				<td class="align-middle">
					<span *ngIf="dataStream.supplier" class="d-block">{{ dataStream.supplier.attributes?.['NAME'] }}</span>
					<small class="d-block">
						{{ dataStream.supplier! | identification : "GLN" }}
					</small>
				</td>
				<td class="align-middle text-center">
					<fa-icon icon="check" class="text-success" *ngIf="dataStream.statusId == 'APPROVED'"></fa-icon>
					<fa-icon icon="clock" class="text-warning" *ngIf="dataStream.statusId == 'REQUESTED'"></fa-icon>
					<fa-icon icon="times" class="text-danger" *ngIf="dataStream.statusId == 'DENIED'"></fa-icon>
				</td>
				<td class="align-middle">
					<span *ngIf="dataStream.requestDate">{{ dataStream.requestDate | date : "dd.MM.yyyy, HH:mm" }} Uhr</span>
				</td>
				<td class="align-middle">
					<span *ngIf="dataStream.responseDate">{{ dataStream.responseDate | date : "dd.MM.yyyy, HH:mm" }} Uhr </span>
				</td>
			</tr>
		</tbody>
	</table>
</div>

<p *ngIf="!loading && !dataStreams?.length" class="px-2">
	{{ "Template.DatastreamOverviewComponent.text_noAccessPermissionsGranted" | translate }}
</p>

<div *ngIf="loading" class="p-5 text-center">
	<fa-icon icon="spinner" animation="spin" size="3x"></fa-icon>
</div>
