import { Pipe, PipeTransform } from '@angular/core';
import { IDataStreamAccess } from '@app/interfaces/idata-stream-access';
import { IOrderAccess } from '@app/interfaces/iorder-access';

@Pipe({
  name: 'statusFilter'
})
export class StatusFilterPipe implements PipeTransform {

  transform(accesses: IDataStreamAccess[] | IOrderAccess[] | null, ...args: string[]): IDataStreamAccess[] | IOrderAccess[] | null {
    if (!accesses) {
      return null;
    }

    const status = args[0];
    if (!status) {
      return accesses;
    }

    return accesses.filter((access: IDataStreamAccess) => {
      return access.statusId === status;
    });
  }

}
