<div class="row">
	<div class="col-12">
		<h3>{{ "Template.DatastreamsComponent.subHeader_manageDatastreams" | translate }}</h3>
	</div>
</div>

<div *ngIf="loading" class="text-center p-5">
	<fa-icon icon="spinner" class="fa-spin" size="3x" animation="spin"></fa-icon>
</div>

<table *ngIf="dataStreams?.length && !loading" class="table table-striped table-hover mt-3">
	<thead>
		<tr>
			<th width="30%" scope="col" sortable="name" [init]="true" (sort)="onSort($event)">
				<fa-icon class="asc" icon="sort-up"></fa-icon>
				<fa-icon class="desc" icon="sort-down"></fa-icon>
				<fa-icon icon="sort"></fa-icon>
				{{ "Template.DatastreamsComponent.text_dataSupplier" | translate }}
			</th>
			<th width="15%" class="text-center" scope="col" sortable="lastImportDate" (sort)="onSort($event)">
				<fa-icon class="asc" icon="sort-up"></fa-icon>
				<fa-icon class="desc" icon="sort-down"></fa-icon>
				<fa-icon icon="sort"></fa-icon>
				Letzter Import
			</th>
			<th width="15%" class="text-center" scope="col" sortable="lastExportDate" (sort)="onSort($event)">
				<fa-icon class="asc" icon="sort-up"></fa-icon>
				<fa-icon class="desc" icon="sort-down"></fa-icon>
				<fa-icon icon="sort"></fa-icon>
				Letzter Export
			</th>
			<th width="10%" class="text-center" scope="col" sortable="productCount" (sort)="onSort($event)">
				<fa-icon class="asc" icon="sort-up"></fa-icon>
				<fa-icon class="desc" icon="sort-down"></fa-icon>
				<fa-icon icon="sort"></fa-icon>
				{{ "Template.DatastreamsComponent.text_products" | translate }}
			</th>
			<th width="10%" class="text-center" scope="col">{{ "Template.DatastreamsComponent.text_logo" | translate }}</th>
			<th width="10%" class="text-center" scope="col">{{ "Template.DatastreamsComponent.text_retrievable" | translate }}</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let dataStream of dataStreams">
			<input type="hidden" name="dataStreamIds[]" value="{{ dataStream.id }}" />
			<td class="align-middle">
				{{ dataStream.supplier?.attributes?.['NAME'] }}
				<small class="d-block mb-1">
					{{ dataStream.supplier! | identification : "GLN" }}
				</small>

				<a *ngIf="dataStream.supplierPartyId" [routerLink]="['/app', 'admin', 'supplier', dataStream.supplierPartyId]" class="btn btn-link btn-sm">
					{{ "Template.DatastreamsComponent.btn_details" | translate }}
					<fa-icon icon="chevron-right"></fa-icon>
				</a>
			</td>
			<td class="align-middle text-center">
				<div *ngIf="dataStream.lastImportDate">
					<span class="d-block">{{ dataStream.lastImportDate | date : "dd.MM.yyyy" }}</span>
					<small class="d-block"> {{ dataStream.lastImportDate | date : "HH:mm" }} Uhr </small>
				</div>
				<div *ngIf="!dataStream.lastImportDate">---</div>
			</td>
			<td class="align-middle text-center">
				<div *ngIf="dataStream.lastExportDate">
					<span class="d-block">{{ dataStream.lastExportDate | date : "dd.MM.yyyy" }}</span>
					<small class="d-block"> {{ dataStream.lastExportDate | date : "HH:mm" }} Uhr </small>
				</div>
				<div *ngIf="!dataStream.lastExportDate">---</div>
			</td>
			<td class="align-middle text-center">
				{{ dataStream.productCount | number }}
			</td>
			<td class="align-middle text-center">
				<div class="logo-image">
					<button *ngIf="dataStream.logoImageUrl" type="button" class="btn btn-link" ngbPopover="Logo vorhanden" triggers="mouseenter:mouseleave">
						<fa-icon icon="check" class="text-success"></fa-icon>
					</button>
					<button *ngIf="!dataStream.logoImageUrl" type="button" class="btn btn-link" ngbPopover="Kein Logo vorhanden" triggers="mouseenter:mouseleave">
						<fa-icon icon="xmark" class="text-danger"></fa-icon>
					</button>
				</div>
			</td>
			<td class="align-middle text-center">
				<div *ngIf="dataStream.autoApprove">
					<fa-icon icon="handshake" class="text-success" ngbPopover="Automatische Freigabe" triggers="mouseenter:mouseleave"></fa-icon>
				</div>

				<div *ngIf="!dataStream.autoApprove">
					<fa-icon icon="handshake" class="text-warning" ngbPopover="Manuelle Freigabe" triggers="mouseenter:mouseleave"></fa-icon>
				</div>
			</td>
		</tr>
	</tbody>
	<caption>
		{{
			"Template.DatastreamsComponent.text_dataStreamsProductsDisabledProducts" | translate : { qtyDatastreams: dataStreams.length, qtyProducts: productCount | number }
		}}
	</caption>
</table>
