<h5>Header-Bild</h5>

<h6>für Brand-Store:</h6>

<p>
	<small>Dieses Bild wird in Ihrem Brand-Store angezeigt. Bitte laden Sie ein Bild mit der Auflösung 1000x250 Pixel (BxH) hoch. </small>
</p>

<div *ngIf="loading" class="p-5 text-center">
	<fa-icon icon="spinner" animation="spin" size="3x"></fa-icon>
</div>

<div class="form-group">
	<input type="file" #filePicker class="form-control form-control-sm" id="image" accept="image/png,image/jpeg" (change)="onImagePicked($event)" />

	<div class="image-preview">
		<img *ngIf="imageForm.controls.imageUrl.value" class="img-fluid" [src]="imageForm.controls.imageUrl.value" />
		<img *ngIf="imageForm.controls.imageData.value" class="img-fluid" [src]="imageForm.controls.imageData.value" />
	</div>
</div>

<button (click)="removeImage()" class="btn btn-sm btn-outline-danger d-block w-100 mb-1" [disabled]="saving" *ngIf="imageForm.controls.imageUrl.value || imageForm.controls.imageData.value">
	Entfernen
	<fa-icon icon="trash" class="ms-1"></fa-icon>
</button>

<button (click)="saveImage()" class="btn btn-sm btn-secondary d-block w-100" [disabled]="imageForm.controls.imageData.value == '' || saving">
	<fa-icon icon="spinner" [spin]="true" *ngIf="saving" class="me-1"></fa-icon>
	Speichern
	<fa-icon icon="check" class="ms-1"></fa-icon>
</button>
