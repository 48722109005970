import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataStream } from '@models/data-stream';
import { DataStreamGroup } from '@models/data-stream-group';
import { Party } from '@models/party';
import { User } from '@models/user';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@services/auth.service';
import { DataStreamService } from '@services/data-stream.service';
import { DialogService } from '@services/dialog.service';
import { EmployerService } from '@services/employer.service';
import { ErrorService } from '@services/error.service';
import { HttpService } from '@services/http.service';
import { PartyService } from '@services/party.service';
import { filter, first, from, Subject, switchMap } from 'rxjs';

@Component({
  selector: 'app-cockpit',
  templateUrl: './cockpit.component.html',
  styleUrls: ['./cockpit.component.scss']
})
export class CockpitComponent implements OnInit {

  public dataStreamGroupIds: string[] = [];
  public allDataStreamsChecked: boolean = false;
  public requestableDataStreams: DataStream[] = [];

  public loadingDataStreams: boolean = false;
  public loadingRequestableDataStreams: boolean = false;
  public loadingDownload: boolean = false;
  public viewSetting: string = 'TILE';
  private partyId: string = '';

  public searchText: string = '';

  public downloadForm!: FormGroup;
  public selectSubject: Subject<boolean> = new Subject<boolean>();

  @ViewChild('requestableDataStreamsModal') private modalContent!: TemplateRef<any>;
  private modalRef!: NgbModalRef;

  public dataStreamRefreshSubject: Subject<void> = new Subject<void>();

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private employerService: EmployerService,
    private partyService: PartyService,
    private datastreamService: DataStreamService,
    private dialogService: DialogService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
    this.loadingDataStreams = true;
    this.loadingRequestableDataStreams = true;
    let employerParty: Party | null = null;

    this.authService.getCurrentUser$().pipe(switchMap((user: User | null) => {
      this.partyId = user?.partyId!;

      return this.partyService.getAttribute(user?.partyId!, 'DATA_STREAM_VIEW_SETTING');
    })).pipe(switchMap((viewSetting: string | null) => {
      if (viewSetting) {
        this.viewSetting = viewSetting;
      }

      return this.employerService.getCurrentEmployerParty$();
    })).pipe(filter(employerParty => !!employerParty)).pipe(switchMap((pEmployerParty) => {
      employerParty = pEmployerParty;

      return from(this.datastreamService.getDataStreamGroupAccesses(employerParty?.partyId)).pipe(first());
    })).pipe(switchMap((dataStreamGroupIds: string[]) => {
      this.dataStreamGroupIds = dataStreamGroupIds;
      this.loadingDataStreams = false;

      return from(this.datastreamService.getRequestableDataStreams("EK_ZR", employerParty?.partyId!));
    })).subscribe((dataStreams: DataStream[]) => {
      this.requestableDataStreams = this.sortDataStreams(dataStreams);
      this.loadingRequestableDataStreams = false;
    });

    this.downloadForm = this.formBuilder.group({
      agreedAgb: [false, Validators.compose([Validators.requiredTrue])],
      dataStreamIds: this.formBuilder.array([], [Validators.required]),
      format: ['XLSX', Validators.compose([Validators.required])],
    });
  }

  private sortDataStreams(dataStreams: DataStream[]): DataStream[] {
    return dataStreams.sort((a: DataStream, b: DataStream) => {
      const aName = a.supplier?.attributes?.["NAME"] || '';
      const bName = b.supplier?.attributes?.["NAME"] || '';

      return aName < bName ? -1 : aName > bName ? 1 : 0
    });
  }

  public selectAllDataStreams(): void {
    this.selectSubject.next(true);
    this.allDataStreamsChecked = true;
  }

  public unselectAllDataStreams(): void {
    this.selectSubject.next(false);
    this.allDataStreamsChecked = false;
  }

  public clearSearch(): void {
    this.searchText = '';
  }

  public requestDownload(): void {
    const formValues = this.downloadForm.value;

    this.loadingDownload = true;
    this.employerService.getCurrentEmployerParty().then((employerParty) => {
      return this.datastreamService.sendGenericDownloadRequest(formValues.dataStreamIds, employerParty?.partyId!, formValues.format);
    }).then(() => {
      this.loadingDownload = false;
    }).catch(() => {
      this.loadingDownload = false;

      this.dialogService.alert(null, 'Download konnte nicht gestartet werden.', 'danger');
    });
  }

  public switchView(viewSetting: string): void {
    this.viewSetting = viewSetting;
    this.partyService.setAttribute(this.partyId, 'DATA_STREAM_VIEW_SETTING', viewSetting);
  }

  public showRequestableDataStreams(): void {
    this.modalRef = this.modalService.open(this.modalContent, {
      size: 'xl',
    })
  }

  public closeContact() {
    this.modalRef.close();
  }

  public refreshDataStreams(): void {
    this.dataStreamRefreshSubject.next();

    this.employerService.getCurrentEmployerParty().then((employerParty) => {
      return this.datastreamService.getRequestableDataStreams("EK_ZR", employerParty?.partyId!);
    }).then((dataStreams: DataStream[]) => {
      this.requestableDataStreams = this.sortDataStreams(dataStreams);
    }).catch((error) => {
      this.errorService.printError(error);
    });
  }

}
