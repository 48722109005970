<div *ngIf="loading" class="p-5 text-center">
	<fa-icon icon="spinner" animation="spin" size="3x"></fa-icon>
</div>

<div *ngIf="!loading && !accesses?.length" class="alert alert-success">
	<p class="mb-0">Derzeit gibt es keine Anfragen.</p>
</div>

<div *ngIf="!loading && (accesses | statusFilter : 'REQUESTED')?.length" class="mb-4">
	<h5 class="mb-0">Ausstehende Anfragen</h5>
	<table class="table table-striped" *ngIf="!loading && accesses?.length">
		<thead>
			<tr>
				<th scope="col">Firma</th>
				<th scope="col">Person</th>
				<th scope="col">Angefragt</th>
				<th scope="col">&nbsp;</th>
			</tr>
		</thead>

		<tbody>
			<tr *ngFor="let access of accesses | statusFilter : 'REQUESTED'">
				<td scope="row" class="align-middle">
					<strong class="d-block">{{ access.customer! | identification : "CUSTOMER_NO" }}</strong>
					<span class="d-block">{{access.customer?.attributes?.["NAME"]}}</span>
				</td>
				<td class="align-middle">
					<span class="d-block">
						{{ access.personFirstname! }}
						{{ access.personLastname! }}
					</span>
					<a [href]="'mailto:' + access.personEmail" class="d-block">{{ access.personEmail }}</a>
				</td>
				<td class="align-middle">{{ access.requestDate | date : "dd.MM.yyyy, HH:mm" }} Uhr</td>
				<td class="text-right align-middle">
					<button (click)="setAccess(access, 'APPROVED')" class="btn btn-success btn-sm d-block w-100" [disabled]="access.loading">
						<fa-icon icon="check" *ngIf="!access.loading"></fa-icon>
						<fa-icon icon="spinner" animation="spin" *ngIf="access.loading"></fa-icon>
						Akzeptieren
					</button>

					<button (click)="setAccess(access, 'DENIED')" class="btn btn-danger btn-sm d-block w-100 mt-1" [disabled]="access.loading">
						<fa-icon icon="times" *ngIf="!access.loading"></fa-icon>
						<fa-icon icon="spinner" animation="spin" *ngIf="access.loading"></fa-icon>
						Ablehnen
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</div>

<div *ngIf="!loading && (accesses | statusFilter : 'APPROVED')?.length" class="mb-4">
	<h5 class="mb-0">Akzeptierte Anfragen</h5>
	<table class="table table-striped" *ngIf="!loading && accesses?.length">
		<thead>
			<tr>
				<th scope="col">Firma</th>
				<th scope="col">Person</th>
				<th scope="col">Angefragt</th>
				<th scope="col">Freigegeben</th>
				<th scope="col">&nbsp;</th>
			</tr>
		</thead>

		<tbody>
			<tr *ngFor="let access of accesses | statusFilter : 'APPROVED'">
				<td scope="row" class="align-middle">
					<strong class="d-block">{{ access.customer! | identification : "CUSTOMER_NO" }}</strong>
					<span class="d-block">{{access.customer?.attributes?.["NAME"]}}</span>
				</td>
				<td class="align-middle">
					<span class="d-block">
						{{ access.personFirstname! }}
						{{ access.personLastname! }}
					</span>
					<a [href]="'mailto:' + access.personEmail" class="d-block">{{ access.personEmail }}</a>
				</td>
				<td class="align-middle">{{ access.requestDate | date : "dd.MM.yyyy, HH:mm" }} Uhr</td>
				<td class="align-middle">{{ access.responseDate | date : "dd.MM.yyyy, HH:mm" }} Uhr</td>
				<td class="text-right align-middle">
					<button (click)="setAccess(access, 'DENIED')" class="btn btn-outline-danger btn-sm d-block w-100" [disabled]="access.loading">
						<fa-icon icon="times" *ngIf="!access.loading"></fa-icon>
						<fa-icon icon="spinner" animation="spin" *ngIf="access.loading"></fa-icon>
						Beenden
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</div>

<div *ngIf="!loading && (accesses | statusFilter : 'DENIED')?.length" class="mb-4">
	<h5 class="mb-0">Abgelehnte Anfragen</h5>
	<table class="table table-striped" *ngIf="!loading && accesses?.length">
		<thead>
			<tr>
				<th scope="col">Firma</th>
				<th scope="col">Person</th>
				<th scope="col">Angefragt</th>
				<th scope="col">Freigegeben</th>
				<th scope="col">&nbsp;</th>
			</tr>
		</thead>

		<tbody>
			<tr *ngFor="let access of accesses | statusFilter : 'DENIED'">
				<td scope="row" class="align-middle">
					<strong class="d-block">{{ access.customer! | identification : "CUSTOMER_NO" }}</strong>
					<span class="d-block">{{access.customer?.attributes?.["NAME"]}}</span>
				</td>
				<td class="align-middle">
					<span class="d-block">
						{{ access.personFirstname! }}
						{{ access.personLastname! }}
					</span>
					<a [href]="'mailto:' + access.personEmail" class="d-block">{{ access.personEmail }}</a>
				</td>
				<td class="align-middle">{{ access.requestDate | date : "dd.MM.yyyy, HH:mm" }} Uhr</td>
				<td class="align-middle">{{ access.responseDate | date : "dd.MM.yyyy, HH:mm" }} Uhr</td>
				<td class="text-right align-middle">
					<button (click)="setAccess(access, 'APPROVED')" class="btn btn-outline-success btn-sm d-block w-100" [disabled]="access.loading">
						<fa-icon icon="check" *ngIf="!access.loading"></fa-icon>
						<fa-icon icon="spinner" animation="spin" *ngIf="access.loading"></fa-icon>
						Freigeben
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</div>
