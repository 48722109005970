<div *ngIf="dataStreamGroup">
	<h3>{{ dataStreamGroup.name }}</h3>

	<div *ngIf="loading" class="text-center px-5 mt-5 mb-4">
		<fa-icon icon="spinner" animation="spin" size="3x"></fa-icon>
	</div>

	<p *ngIf="!loading && !dataStreams?.length">Für Sie wurden noch keine Daten in diesem Sortiment bereitgestellt.</p>

	<div class="row" *ngIf="dataStreams?.length">
		<app-data-stream *ngFor="let dataStream of dataStreams | datastreamFilter : searchText" [dataStream]="dataStream" [viewSetting]="viewSetting" [parentFormGroup]="parentFormGroup" [supplierContact]="dataStream.contact!" [select]="selectSubject.asObservable()" class="col-12 mb-3 datastream" [ngClass]="{ 'col-md-6 col-lg-4': viewSetting == 'TILE' }"></app-data-stream>
	</div>
</div>
