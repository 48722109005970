import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';

import { DataStreamService } from '@services/data-stream.service';
import { DialogService } from '@services/dialog.service';

import { DataStream } from '@models/data-stream';

import { IRequestParameters } from '@app/interfaces/irequest-parameters';
import { SortableHeader, SortDirection } from '@app/directives/sortable-header';

const compare = (v1: string | number, v2: string | number) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

@Component({
  selector: 'app-data-streams',
  templateUrl: './data-streams.component.html',
  styleUrls: ['./data-streams.component.scss'],
})
export class DataStreamsComponent implements OnInit {

  public requestParameters: IRequestParameters[] = [];
  public dataStreams: DataStream[] = [];
  public productCount: number = 0;
  public sortOrder: number = 1; // 1 = asc | -1 = desc
  public loading: boolean = false;

  constructor(
    private dataStreamService: DataStreamService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    this.loading = true;

    this.getDataStreams().then((dataStreams: DataStream[]) => {
      this.dataStreams = dataStreams;
      this.loading = false;
      this.countProducts(dataStreams);
      this.onSort({ column: 'name', direction: 'asc' });
    }).catch((error: any) => {
      this.dialogService.alert(null, 'Beim Laden der Datenstreams ist ein Fehler aufgetreten.', 'danger');
      this.loading = false;
    });
  }

  private getDataStreams(): Promise<DataStream[]> {
    return this.dataStreamService.getDataStreams();
  }

  private countProducts(dataStreams: DataStream[]): void {
    let count = 0;
    for (let dataStream of dataStreams) {
      count += dataStream.productCount || 0;
    }

    this.productCount = count;
  }

  @ViewChildren(SortableHeader) headers!: QueryList<SortableHeader>;
  public onSort(event: any) {
    let column = event.column as string;
    let direction = event.direction as SortDirection;

    // resetting other headers
    for (const header of this.headers) {
      if (header.sortable !== column) {
        header.direction = '';
      }
    }

    if (direction === '' || column === '') {
      direction = 'asc';
      column = 'name';
    }

    if (column == 'name') {
      this.dataStreams = [...this.dataStreams].sort((a: any, b: any) => {
        const res = compare(a.supplier?.attributes?.['NAME']?.toLowerCase(), b.supplier?.attributes?.['NAME']?.toLowerCase());
        return direction === 'asc' ? res : -res;
      });
    } else {
      this.dataStreams = [...this.dataStreams].sort((a: any, b: any) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }
}