import { Pipe, PipeTransform } from '@angular/core';
import { Party } from '@models/party';

@Pipe({
  name: 'partyRel'
})
export class PartyRelPipe implements PipeTransform {

  transform(party: Party, ...args: string[]): boolean {
    if (!party || !party.relationships) {
      return false;
    }

    return party.relationships.some((rel) => rel.type === args[0]);
  }

}
