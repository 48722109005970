import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { DialogService } from '@services/dialog.service';
import { SupplierService } from '@services/supplier.service';

import { IDeliveryConditions } from '@app/interfaces/idelivery-conditions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-delivery-conditions',
  templateUrl: './delivery-conditions.component.html',
  styleUrls: ['./delivery-conditions.component.scss']
})
export class DeliveryConditionsComponent implements OnInit, OnDestroy {

  @Input() partyId: string | null = null;

  @Input() refresh: Observable<void> = new Observable<void>();
  private refreshSubscription!: Subscription;

  public deliveryConditions: IDeliveryConditions | null = null;

  public deliveryConditionsForm: FormGroup;
  public defaultFormValues: any = {};
  public loading: boolean = false;
  public saving: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private supplierService: SupplierService,
    private dialogService: DialogService,
  ) {
    this.deliveryConditionsForm = this.formBuilder.group({
      deliveryMinimumLimit: [{
        value: "",
        disabled: true, // disabled, because otherwise the input field appears
      }, Validators.compose([Validators.required])],
      deliveryNormalLimit: [{
        value: "",
        disabled: true,
      }, Validators.compose([Validators.required])],
      minimumQuantitySurcharge: [{
        value: "",
        disabled: true,
      }, Validators.compose([Validators.required])],
      deliveryNotes: [{
        value: "",
        disabled: true,
      }, Validators.compose([Validators.required])],
      editing: [false],
    });
  }

  ngOnInit(): void {
    if (!this.partyId) {
      return;
    }

    this.refreshDeliveryConditions();

    this.refreshSubscription = this.refresh.subscribe(() => {
      this.refreshDeliveryConditions();
    });
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }

  private refreshDeliveryConditions() {
    this.loading = true;

    this.supplierService.getDeliveryConditions(this.partyId!).then((deliveryConditions: IDeliveryConditions | null) => {
      deliveryConditions = {
        ...deliveryConditions,
        deliveryMinimumLimit: deliveryConditions?.deliveryMinimumLimit! > 0 ? deliveryConditions?.deliveryMinimumLimit : null,
        deliveryNormalLimit: deliveryConditions?.deliveryNormalLimit! > 0 ? deliveryConditions?.deliveryNormalLimit : null,
        minimumQuantitySurcharge: deliveryConditions?.minimumQuantitySurcharge! > 0 ? deliveryConditions?.minimumQuantitySurcharge : null,
      } as IDeliveryConditions;

      this.defaultFormValues = deliveryConditions || {};
      this.deliveryConditionsForm.patchValue({ ...deliveryConditions });
      this.loading = false;
    }).catch(() => {
      this.loading = false;
      this.dialogService.alert(null, "Wir konnten Ihre Lieferkonditionen nicht laden.", "danger");
    });
  }

  public saveDeliveryConditions(): void {
    this.saving = true;

    this.supplierService.setDeliveryConditions(this.partyId || '', this.deliveryConditionsForm.value).then(() => {
      this.saving = false;

      this.defaultFormValues = this.deliveryConditionsForm.value;

      this.dialogService.alert(null, "Ihre Lieferkonditionen wurden erfolgreich gespeichert.", "success");
      this.showForm(false);
    }).catch(() => {
      this.saving = false;
      this.dialogService.alert(null, "Wir konnten Ihre Lieferkonditionen nicht speichern.", "danger");
    });
  }

  public showForm(edit: boolean) {
    if (edit) {
      this.deliveryConditionsForm.controls['editing'].patchValue(true);

      Object.keys(this.deliveryConditionsForm.controls).forEach((fieldName: string) => {
        this.deliveryConditionsForm.controls[fieldName].enable();
      });
    } else {
      Object.keys(this.deliveryConditionsForm.controls).forEach((fieldName: string) => {
        this.deliveryConditionsForm.controls[fieldName].patchValue(this.defaultFormValues[fieldName] || '');
        this.deliveryConditionsForm.controls[fieldName].disable();
      });
      
      this.deliveryConditionsForm.controls['editing'].patchValue(false);
    }
  }

}
