import { Injectable } from '@angular/core';

import { ErrorService } from '@services/error.service';

import { PartyService } from './party.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(
    private errorService: ErrorService,
    private partyService: PartyService
  ) { }

  public updatePartyEnabled(
    enabled: boolean,
    partyId?: string,
  ): Promise<void> {
    return this.partyService.updateParty({
      enabled: (enabled ? 'Y' : 'N'),
      partyId,
    });
  }


}
