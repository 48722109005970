<div class="titlebar">
	<h3>
		{{ "Template.SupplierDetailComponent.subHeader_supplier" | translate }}<span *ngIf="supplier">: {{ supplier.attributes?.['NAME'] }}</span>
	</h3>

	<div class="buttons">
		<button class="btn btn-secondary" [disabled]="loading['supplier']" (click)="reload()">
			<fa-icon icon="arrow-rotate-right"></fa-icon>
		</button>
	</div>
</div>

<fa-icon icon="spinner" [spin]="true" *ngIf="loading['supplier']"></fa-icon>

<div *ngIf="supplier">
	<ul class="nav nav-tabs" ngbNav #tabs="ngbNav">
		<li class="nav-item" ngbNavItem>
			<a class="nav-link" ngbNavLink="#details">{{ "Template.SupplierDetailComponent.text_details" | translate }}</a>

			<ng-template ngbNavContent>
				<div class="tab-pane fade show" id="details" role="tabpanel">
					<h5 class="mb-2">{{ "Template.SupplierDetailComponent.subHeader_generalInformation" | translate }}:</h5>
					<div class="row">
						<div class="col-6 col-xs-12 px-4">
							<div class="row">
								<div class="col-6">
									<strong>{{ "Template.SupplierDetailComponent.text_gln" | translate }}:</strong>
								</div>
								<div class="col-6">
									{{ supplier! | identification : "GLN" }}
								</div>
							</div>

							<div class="row">
								<div class="col-6">
									<strong>{{ "Template.SupplierDetailComponent.text_name" | translate }}:</strong>
								</div>
								<div class="col-6">
									{{ supplier.attributes?.['NAME'] }}
								</div>
							</div>

							<div class="row">
								<div class="col-6">
									<strong>{{ "Template.SupplierDetailComponent.text_enabled" | translate }}:</strong>
								</div>
								<div class="col-6">
									<span *ngIf="supplier.enabled">{{ "Template.SupplierDetailComponent.text_yes" | translate }}</span>
									<strong class="text-danger" *ngIf="!supplier.enabled">
										{{ "Template.SupplierDetailComponent.text_no" | translate }}
										<fa-icon icon="exclamation-triangle"></fa-icon>
									</strong>
								</div>
							</div>
						</div>
						<div class="col-6 col-xs-12">
							<button class="btn btn-warning d-block w-100" *ngIf="supplier.enabled" (click)="toggleSupplier(false)">{{ "Template.SupplierDetailComponent.btn_disable" | translate }}</button>
							<button class="btn btn-success d-block w-100" *ngIf="!supplier.enabled" (click)="toggleSupplier(true)">{{ "Template.SupplierDetailComponent.btn_enable" | translate }}</button>
						</div>
					</div>

					<hr />

					<!-- nested/child component -->
					<app-employees *ngIf="supplier" [isCustomer]="false" [party]="supplier"></app-employees>
				</div>
			</ng-template>
		</li>

		<li class="nav-item" ngbNavItem>
			<a class="nav-link" ngbNavLink="#upload">{{ "Template.SupplierDetailComponent.text_dataManagement" | translate }}</a>
			<ng-template ngbNavContent>
				<div class="tab-pane fade show" id="upload" role="tabpanel">
					<button class="btn d-block w-100 mb-2" (click)="toggleDataUploadRel()" [ngClass]="{ 'btn-outline-danger': (supplier | partyRel : 'SUPPLIER_DATA_UPLOAD_RELATIONSHIP'), 'btn-success': !(supplier | partyRel : 'SUPPLIER_DATA_UPLOAD_RELATIONSHIP') }" [disabled]="loading['dataUploadRel']">
						<span *ngIf="supplier | partyRel : 'SUPPLIER_DATA_UPLOAD_RELATIONSHIP'">Daten-Upload deaktivieren</span>
						<span *ngIf="!(supplier | partyRel : 'SUPPLIER_DATA_UPLOAD_RELATIONSHIP')">Daten-Upload aktivieren</span>
						<fa-icon icon="spinner" [spin]="true" *ngIf="loading['dataUploadRel']" class="ms-2"></fa-icon>
					</button>

					<hr *ngIf="supplier | partyRel : 'SUPPLIER_DATA_UPLOAD_RELATIONSHIP'" />

					<app-data-management *ngIf="id && (supplier | partyRel : 'SUPPLIER_DATA_UPLOAD_RELATIONSHIP')" [partyId]="id"></app-data-management>
				</div>
			</ng-template>
		</li>

		<li class="nav-item" ngbNavItem *ngIf="supplier | partyRel : 'SUPPLIER_DATA_STREAM_RELATIONSHIP'">
			<a class="nav-link" ngbNavLink="#dataAccesses">{{ "Template.SupplierDetailComponent.text_dataStream" | translate }}</a>
			<ng-template ngbNavContent>
				<div class="tab-pane fade show" id="dataAccesses" role="tabpanel">
					<div *ngFor="let dataStream of dataStreams">
						<h4 class="fs-5 text-primary" *ngIf="dataStreams && dataStreams.length > 1">{{ dataStream.supplier?.attributes?.['NAME'] }}</h4>
						<app-data-approvals [dataStreamId]="dataStream.id"></app-data-approvals>
					</div>
				</div>
			</ng-template>
		</li>
		<li class="nav-item" ngbNavItem *ngIf="supplier | partyRel : 'SUPPLIER_ORDER_RELATIONSHIP'">
			<a class="nav-link" ngbNavLink="#orderAccesses">{{ "Template.SupplierDetailComponent.text_connectionToZrBusiness" | translate }}</a>
			<ng-template ngbNavContent>
				<div class="tab-pane fade show" id="orderAccesses" role="tabpanel">
					<app-order-accesses *ngIf="id" [partyId]="id"></app-order-accesses>
				</div>
			</ng-template>
		</li>
	</ul>

	<div class="tab-content" [ngbNavOutlet]="tabs"></div>
</div>
