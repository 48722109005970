<div class="titlebar">
	<h3>{{ "Template.CockpitComponent.subHeader_supplierHomepage" | translate }}</h3>
</div>

<fa-icon icon="spinner" [spin]="true" *ngIf="loading"></fa-icon>

<div *ngIf="!loading">
	<ul ngbNav #nav="ngbNav" class="nav-tabs border-bottom-0 ps-2">
		<li ngbNavItem *ngIf="showDataUpload">
			<button ngbNavLink>Daten-Management</button>
			<ng-template ngbNavContent>
				<app-data-management *ngIf="currentPartyId" [partyId]="currentPartyId"></app-data-management>
			</ng-template>
		</li>
		<li ngbNavItem *ngIf="showDataApproval">
			<button ngbNavLink>Daten-Freigaben</button>
			<ng-template ngbNavContent>
				<div class="row">
					<div class="col-6">
						<app-supplier-contact *ngIf="currentPartyId" [partyId]="currentPartyId" [type]="'SUPPLIER_DATA'"></app-supplier-contact>
					</div>
					<div class="col-6">
						<div *ngFor="let dataStream of dataStreams">
							<h4 class="fs-5 text-primary" *ngIf="dataStreams && dataStreams.length > 1">{{ dataStream.supplier?.attributes?.['NAME'] }}</h4>
							<app-auto-approve *ngIf="currentPartyId" [partyId]="currentPartyId" [dataStream]="dataStream" (refresh)="refreshDataAccesses()"></app-auto-approve>
						</div>
					</div>
				</div>

				<hr class="mb-5" />

				<div *ngFor="let dataStream of dataStreams">
					<h4 class="fs-5 text-primary" *ngIf="dataStreams && dataStreams.length > 1">{{ dataStream.supplier?.attributes?.['NAME'] }}</h4>
					<app-data-approvals [dataStreamId]="dataStream.id" [refresh]="dataAccessesRefreshSubject"></app-data-approvals>
				</div>
			</ng-template>
		</li>
		<li ngbNavItem *ngIf="showOrderApproval">
			<button ngbNavLink>Bestell-Freigaben</button>
			<ng-template ngbNavContent>
				<div class="row">
					<div class="col-4">
						<app-supplier-contact *ngIf="currentPartyId" [partyId]="currentPartyId" [type]="'SUPPLIER_ORDERS'"></app-supplier-contact>
					</div>
					<div class="col-4">
						<app-delivery-conditions *ngIf="currentPartyId" [partyId]="currentPartyId"></app-delivery-conditions>
					</div>
					<div class="col-4">
						<app-supplier-header-image-upload *ngIf="currentPartyId" [partyId]="currentPartyId"></app-supplier-header-image-upload>
					</div>
				</div>

				<hr class="mb-5" />

				<app-order-accesses *ngIf="currentPartyId" [partyId]="currentPartyId"></app-order-accesses>
			</ng-template>
		</li>
	</ul>

	<div class="p-3 border rounded-3" [ngbNavOutlet]="nav"></div>
</div>
