import { Injectable } from '@angular/core';
import { HttpService } from '@services/http.service';
import { ErrorService } from '@services/error.service';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class LangService {

  constructor(
    private translateService: TranslateService,
    private httpService: HttpService,
    private errorService: ErrorService
  ) { }


  setLanguage(language: string): void {
    this.translateService.use(language);
  }

  getCurrentLanguage(): string {
    return this.translateService.currentLang;
  }


  public setLangAttribute(partyId: string, language: string): Promise<any | null> {

    return new Promise((resolve, reject) => {
      this.httpService.post(`**/parties/setLangAttribute`, {
        id: partyId,
        language: language
      }).then((response) => {
        if (response) {
          resolve(response.body);
        } else {
          reject('No response received.');
        }
      }).catch((error: any) => {
        this.errorService.printError(error);
        return reject(error);
      })
    });
  }

  public getLangAttribute(partyId: string): Promise<any | null> {

    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/parties/getLangAttribute`, {
        id: partyId,
      }).then((response) => {
        if (response) {
          resolve(response.language);
        }
        else {
          reject('No response received.');
        }
      }).catch((error: any) => {
        this.errorService.printError(error);
        return reject(error);
      })
    })
  }
}

