<h5 class="mb-3">
	{{ "Template.EmployeesComponent.subHeader_userAccesses" | translate }}:
	<button *ngIf="party" class="btn btn-sm btn-success float-end" (click)="createOrUpdateUser()" [disabled]="!party.enabled">
		<fa-icon icon="user-plus"></fa-icon>
		{{ "Template.EmployeesComponent.btn_createUserAccess" | translate }}
	</button>
</h5>

<table class="table" *ngIf="!loadingEmployees && employees?.length">
	<tbody>
		<ng-template ngFor let-employee [ngForOf]="employees" let-i="index">
			<tr class="border-bottom">
				<td>
					<app-employee [username]="employee" [party]="party" [isCustomer]="isCustomer" (loadingEvent)="showLoader($event)" (reloadEvent)="reload()"></app-employee>
				</td>
			</tr>
		</ng-template>
	</tbody>
</table>

<strong *ngIf="!loadingEmployees && (!employees || !employees.length)">{{ "Template.EmployeesComponent.text_noUserAccessesCreated" | translate }}</strong>

<fa-icon *ngIf="loadingEmployees" icon="spinner" size="2x" [spin]="true" class="ms-3"></fa-icon>
