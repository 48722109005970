<h5>Ansprechpartner</h5>

<h6 *ngIf="type == 'SUPPLIER_DATA'">für Daten-Freigaben:</h6>
<h6 *ngIf="type == 'SUPPLIER_ORDERS'">für Bestell-Freigaben:</h6>

<p>
	<small> Bitte geben Sie einen Ansprechpartner an, der für den Handelspartner zur Verfügung steht. Dieser wird in der Kommunikation zum Handelspartner als Ansprechpartner für Rückfragen genannt. </small>
</p>

<div class="text-center" *ngIf="loading">
	<fa-icon icon="spinner" [spin]="loading" size="2x"></fa-icon>
</div>

<form [formGroup]="contactForm" novalidate *ngIf="!loading">
	<div class="form-group mb-2">
		<label for="firstname" class="mb-0">Name {{ contactForm.controls["editing"].value }}</label>
		<div class="row">
			<div class="col">
				<input type="text" [ngClass]="contactForm.controls['firstname'].disabled ? 'form-control-plaintext' : 'form-control'" formControlName="firstname" placeholder="Vorname" />
			</div>
			<div class="col">
				<input type="text" [ngClass]="contactForm.controls['lastname'].disabled ? 'form-control-plaintext' : 'form-control'" formControlName="lastname" placeholder="Nachname" />
			</div>
		</div>
	</div>
	<div class="form-group mb-2">
		<label for="email" class="mb-0">E-Mail-Adresse</label>
		<input type="email" [ngClass]="contactForm.controls['email'].disabled ? 'form-control-plaintext' : 'form-control'" formControlName="email" placeholder="E-Mail" />
	</div>
	<div class="form-group mb-2">
		<label for="phone" class="mb-0">Telefon</label>
		<input type="tel" [ngClass]="contactForm.controls['phone'].disabled ? 'form-control-plaintext' : 'form-control'" formControlName="phone" placeholder="Telefon" />
	</div>
	<button class="btn btn-sm btn-block w-100" (click)="showForm(!contactForm.controls['editing'].value)" type="button" [ngClass]="contactForm.controls['editing'].value ? 'btn-outline-danger' : 'btn-secondary'">
		<span *ngIf="!contactForm.controls['editing'].value">Bearbeiten</span>
		<span *ngIf="contactForm.controls['editing'].value">Abbrechen</span>
		<fa-icon icon="pencil-alt" *ngIf="!contactForm.controls['editing'].value" class="ms-1"></fa-icon>
		<fa-icon icon="circle-xmark" *ngIf="contactForm.controls['editing'].value" class="ms-1"></fa-icon>
	</button>
	<button type="button" [attr.disabled]="saving ? '' : null" class="btn btn-secondary btn-sm mt-2 btn-block w-100" *ngIf="contactForm.controls['editing'].value" (click)="saveContact()">
		Speichern
		<fa-icon icon="check" *ngIf="!saving" class="ms-1"></fa-icon>
		<fa-icon icon="spinner" [spin]="true" *ngIf="saving" class="ms-1"></fa-icon>
	</button>
</form>
