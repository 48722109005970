import { Injectable } from '@angular/core';
import { Supplier } from '@models/supplier';
import { AuthService } from './auth.service';
import { ErrorService } from './error.service';
import { HttpService } from './http.service';
import { IOrderAccess } from '@app/interfaces/iorder-access';
import { IDeliveryConditions } from '@app/interfaces/idelivery-conditions';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    private errorService: ErrorService
  ) { }

  public getSuppliers(relType: string): Promise<Supplier[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/suppliers/getAll`, {
        partyRelationshipType: relType,
      }).then((response) => {
        return resolve(response);
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject();
      });
    });
  }

  public getSupplier(id: string | null): Promise<Supplier> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/suppliers/get`, {
        partyId: id,
      }).then((response: Supplier) => {
        return resolve(response);
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject();
      });
    });
  }

  public createSupplier(
    name: string,
    gln: string,
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/suppliers/create`, {
        gln,
        name,
      }).then((result: any) => {
        return resolve(result.partyId);
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    })
  }

  public getSupplierEmployees(partyId: string): Promise<string[]> {
    let employees: string[] = [];
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/suppliers/getEmployees`, { partyId }).then((result: string[]) => {
        if (result) {
          for (const employee of result) {
            if (employee) {
              employees.push(employee);
            }
          }
        }
        return this.authService.refreshUser();
      }).then(() => {
        return resolve(employees);
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    })
  }

  public getOrderAccesses(partyId: string): Promise<IOrderAccess[] | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.get("**/suppliers/getOrderAccesses", { partyId }).then((response: IOrderAccess[] | null) => {
        return resolve(response);
      }).catch((err) => {
        return reject(err);
      })
    })
  }

  public setOrderAccess(partyId: string, customerPartyId: string, statusId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/suppliers/setOrderAccess`, {
        partyId,
        customerPartyId,
        statusId,
      }).then((result: any) => {
        return resolve();
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    });
  }

  public getDeliveryConditions(partyId: string): Promise<IDeliveryConditions | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.get("**/suppliers/getDeliveryConditions", { partyId }).then((response: IDeliveryConditions | null) => {
        return resolve(response);
      }).catch((err) => {
        return reject(err);
      })
    })
  }

  public setDeliveryConditions(partyId: string, deliveryConditions: IDeliveryConditions): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/suppliers/setDeliveryConditions`, {
        ...deliveryConditions,
        partyId,
      }).then((result: any) => {
        return resolve();
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    });
  }

  public getHeaderImage(partyId: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/suppliers/getHeaderImage`, { partyId }).then((response: any | null) => {
        return resolve(response?.url);
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject();
      });
    });
  }

  public setHeaderImage(partyId: string, file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('file', file);
      formData.append('partyId', partyId);

      return this.httpService.post(`**/suppliers/setHeaderImage`, formData, { fullResponse: true }).then(() => {
        return resolve();
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    })
  }

  public toggleDataUploadRelationship(partyId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/suppliers/toggleDataUploadRelationship`, { partyId }).then((response: any) => {
        return resolve();
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject();
      });
    });
  }

}
