<form [formGroup]="passwordForm" id="changepasswordform" (ngSubmit)="onSubmit($event)">
	<fieldset>
		<div>
			<label for="oldPassword">{{ "Template.PasswordChangeComponent.label_oldPassword" | translate }}*</label>
			<input type="password" class="form-control inputBox" name="oldPassword" id="oldPassword" formControlName="oldPassword" maxlength="20" autocomplete="off" data-lpignore="true" />
      <small class="text-danger" *ngIf="passwordForm.get('oldPassword')?.errors?.['oldPasswordInvalid']">Das alte Passwort ist leider nicht korrekt.</small>
		</div>
		<div class="mt-4">
			<label for="newPassword">{{ "Template.PasswordChangeComponent.label_newPassword" | translate }}* </label>
			<input type="password" class="form-control inputBox" name="newPassword" id="newPassword" formControlName="newPassword" maxlength="20" autocomplete="off" data-lpignore="true" ngbTooltip="{{ 'Template.PasswordChangeComponent.tooltip_newPassword' | translate }}" (input)="onNewPasswordInput()" />
			<app-password-strength [passwordToCheck]="newPassword"></app-password-strength>
			<small class="text-danger" *ngIf="passwordForm && passwordForm.get('newPassword') && passwordForm.get('newPassword')?.errors && passwordForm.get('newPassword')?.hasError('pattern')">
				{{ "Template.PasswordChangeComponent.msg_errorNoCriteriaMatch" | translate }}
			</small>
		</div>
		<div class="mt-4">
			<label for="newPasswordVerify">{{ "Template.PasswordChangeComponent.label_newPasswordVerify" | translate }}* </label>
			<input type="password" class="form-control inputBox" name="newPasswordVerify" id="newPasswordVerify" formControlName="newPasswordVerify" maxlength="20" autocomplete="off" data-lpignore="true" />
		</div>
	</fieldset>

	<div class="mt-2">
		<label>
			<small>{{ "Template.PasswordChangeComponent.label_commonFieldsMarkedAreRequired" | translate }}</small>
		</label>
	</div>

	<div class="mt-2">
		<button type="submit" class="btn btn-success d-block w-100" [disabled]="!passwordForm.valid">
			{{ "Template.PasswordChangeComponent.btn_save" | translate }}
			<fa-icon icon="chevron-right" *ngIf="!loadingSave"></fa-icon>
			<fa-icon icon="spinner" [spin]="true" *ngIf="loadingSave"></fa-icon>
		</button>
	</div>
</form>
