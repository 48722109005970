<div class="row">  
    <div class="col-xs-12 col-sm-8 col-md-6 col-lg-4 mx-auto">
      <h3 class="text-center">EK MedienCenter: Passwort zurücksetzen</h3>
  
      <ng-template [ngIf]="isResetVerification">
        <p class="text-center">Bitte geben Sie Ihre E-Mail-Adresse ein, sodass wir Ihnen einen Link zum Zurücksetzen des Passworts zusenden können.</p>
        <form (ngSubmit)="checkEmail()" [formGroup]="recoverPasswordForm" novalidate class="mt-2">
          <div class="form-group">
            <label for="email">E-Mail-Adresse</label>
            <input type="text" class="form-control" id="email" placeholder="E-Mail-Adresse" formControlName="email" autocomplete="email" required>
            <small *ngIf="!recoverPasswordForm.controls['email'].valid && !recoverPasswordForm.controls['email'].untouched" class="text-danger">
              Bitte geben Sie Ihre E-Mail-Adresse ein.
            </small>    
          </div>    
          <button type="submit" class="btn btn-secondary d-block w-100" [disabled]="isLoading || !recoverPasswordForm.valid">
            Passwort zurücksetzen
            <fa-icon *ngIf="isLoading" icon="spinner" [spin]="true" class="ml-1"></fa-icon>
          </button>
        </form>
      </ng-template>

      <ng-template [ngIf]="!isResetVerification">
        <p class="text-center">Ihr Passwort wurde erfolgreich zurückgesetzt. Sie erhalten in Kürze eine E-Mail mit einem neuen Passwort.</p>    
      </ng-template>
    </div>
</div>
