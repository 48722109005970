import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { DataStream } from '@models/data-stream';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@services/auth.service';
import { DataStreamService } from '@services/data-stream.service';
import { DialogService } from '@services/dialog.service';
import { EmployerService } from '@services/employer.service';

@Component({
  selector: 'app-request-data-stream',
  templateUrl: './request-data-stream.component.html',
  styleUrls: ['./request-data-stream.component.scss']
})
export class RequestDataStreamComponent implements OnInit {

  @Input() dataStream!: DataStream;
  @Output() refresh: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('contactModal') private modalContent!: TemplateRef<any>;
  private modalRef!: NgbModalRef;

  public newMinDate: Date = new Date();

  public sendingRequest: boolean = false;

  constructor(
    private modalService: NgbModal,
    private dataStreamService: DataStreamService,
    private dialogService: DialogService,
    private employerService: EmployerService,
    private authService: AuthService,
  ) {
    this.newMinDate.setDate(this.newMinDate.getDate() - 14);
  }

  ngOnInit(): void {
  }

  public showContact(): void {
    this.modalRef = this.modalService.open(this.modalContent)
  }

  public closeContact() {
    this.modalRef.close();
  }

  public sendRequest(): void {
    this.sendingRequest = true;

    let user: any = null;
    this.authService.getCurrentUser().then((pUser) => {
      user = pUser;
      return this.employerService.getCurrentEmployerParty();
    }).then((employerParty) => {
      return this.dataStreamService.sendDataStreamAccessRequest(this.dataStream.id, employerParty?.partyId!, user?.partyId!);
    }).then(() => {
      this.sendingRequest = false;
      this.dataStream.statusId = 'REQUESTED';
      this.refresh.emit();
    }).catch(() => {
      this.sendingRequest = false;

      this.dialogService.alert(null, 'Anfrage konnte nicht gesendet werden.', 'danger');
    });
  }

}
